import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Public',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Login',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/Register')
        },
        {
          name: 'Tournaments',
          path: 'tournaments',
          component: () => import('@/views/pages/Torneos')
        },
        {
          name: 'TournamentsDetail',
          path: 'tournaments/:id',
          component: () => import('@/views/pages/TorneosDetalle')
        },
        {
          name: 'JourneyDetail',
          path: 'journeys/:id',
          component: () => import('@/views/pages/JornadaDetalle')
        }
      ],
    },
    {
      path: '/admin',
      name: 'Admin',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'AdminDashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'AdminSucursales',
          path: 'branch',
          component: () => import('@/views/dashboard/views/sucursales/Sucursales'),
        },
        {
          name: 'AdminTorneos',
          path: 'tournament',
          component: () => import('@/views/dashboard/views/torneos/Torneos'),
        },
        {
          name: 'AdminCanchas',
          path: 'field',
          component: () => import('@/views/dashboard/views/canchas/Canchas'),
        },
        {
          name: 'AdminEquipos',
          path: 'team',
          component: () => import('@/views/dashboard/views/equipos/Equipos'),
        },
        {
          name: 'AdminArbitros',
          path: 'referee',
          component: () => import('@/views/dashboard/views/arbitros/Arbitros'),
        },
        {
          name: 'AdminPosiciones',
          path: 'position',
          component: () => import('@/views/dashboard/views/posiciones/Posiciones'),
        },
        {
          name: 'AdminEventos',
          path: 'event',
          component: () => import('@/views/dashboard/views/eventos/Eventos'),
        },
        {
          name: 'AdminJugadores',
          path: 'player',
          component: () => import('@/views/dashboard/views/jugadores/Jugadores'),
        },
        {
          name: 'AdminJornadas',
          path: 'journey',
          component: () => import('@/views/dashboard/views/jornadas/Jornadas'),
        },
        {
          name: 'AdminJuegosInformales',
          path: 'informal-play',
          component: () => import('@/views/dashboard/views/juegos_informales/JuegosInformales'),
        },
        {
          name: 'AdminUsuarios',
          path: 'user',
          component: () => import('@/views/dashboard/views/usuarios/Usuarios'),
        },
        {
          name: 'AdminUsuariosSistema',
          path: 'system-user',
          component: () => import('@/views/dashboard/views/usuarios-sistema/Usuarios'),
        },
        {
          name: 'AdminUsuarioPerfil',
          path: 'user/profile',
          component: () => import('@/views/dashboard/views/usuarios/UsuarioPerfil')
        },
        {
          name: 'AdminRoles',
          path: 'role',
          component: () => import('@/views/dashboard/views/roles/Roles')
        },
        {
          name: 'AdminTipoIdentificacion',
          path: 'id-type',
          component: () => import('@/views/dashboard/views/tipo_identificacion/TipoIdentificacion')
        },
        {
          name: 'AdminConceptosPago',
          path: 'payment-concepts',
          component: () => import('@/views/dashboard/views/conceptos_pago/ConceptosPago')
        },
        {
          name: 'AdminCalendar',
          path: 'calendar',
          component: () => import('@/views/dashboard/Calendar'),
        },
        {
          name: 'AdminDeportes',
          path: 'sport',
          component: () => import('@/views/dashboard/views/deportes/Deportes')
        },
        {
          name: 'AdminCostoHorarios',
          path: 'rate',
          component: () => import('@/views/dashboard/views/costo_horarios/CostoHorario')
        },
        {
          name: 'AdminPagos',
          path: 'payment',
          component: () => import('@/views/dashboard/views/pagos/Pago')
        },
        {
          name: 'AdminDivisas',
          path: 'currency',
          component: () => import('@/views/dashboard/views/divisas/Divisas')
        },
        {
          name: 'AdminConversionDivisas',
          path: 'currency-conversion',
          component: () => import('@/views/dashboard/views/conversion_divisas/ConversionDivisas')
        },
        {
          name: 'AdminTipoConcepto',
          path: 'payment-concept-type',
          component: () => import('@/views/dashboard/views/tipo_conceptos/TipoConcepto')
        },
        {
          name: 'AdminFormasPago',
          path: 'payment-method',
          component: () => import('@/views/dashboard/views/formas_pago/FormasPago')
        },
        {
          name: 'AdminRentasConfig',
          path: 'rent-config',
          component: () => import('@/views/dashboard/views/rentas_config/RentasConfig')
        },
      ],
    },
  ],
})
